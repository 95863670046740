import {connect} from 'react-redux'
import {List, Map} from 'immutable'
import {useState} from 'react'
import PropTypes from 'prop-types'

import AddDeputyController from './AddDeputyController'
import CancelDeputy from './CancelDeputy'
import DeputyAddedConfirmation from './DeputyAddedConfirmation'
import MediumModal from '../../../../../shared_components/mui_base_components/modals/medium_modal/MediumModal'

import {deputizableDomainEmails} from '../../../../../lib/emailTools'
import {findRawResourceByAttributeId, findRawResourceById, findResourceById} from '../../../../../lib/plan_data/userData'
import {useScoreLedgerEventLogger} from '../../../../../lib/ScoreLedgerEventLogger'

import './addDeputyModal.scss'

const AddDeputyModal = props => {
  const {logWithNotification} = useScoreLedgerEventLogger(props.userConfig)
  const [showDeputyAddedConfirmation, setShowDeputyAddedConfirmation] = useState(false)
  const [showWarning, setShowWarning] = useState(false)

  const contact = findResourceById({resourceList: props.newContacts, id: props.params?.contactId})

  const [deputy, setDeputy] = useState({
    'first-name': contact.get('first-name', ''),
    'last-name': contact.get('last-name', ''),
    email: deputizableDomainEmails({contact, organization: props.organization}).first() || ''
  })

  const toggleWarning = () => { setShowWarning(!showWarning) }

  const warningModal = () => {
    if (showWarning)
      return <CancelDeputy hideWarning={toggleWarning} exit={props.exit} />
  }

  const handleSubmitCompleted = () => {
    setShowDeputyAddedConfirmation(true)
    props.context === 'dashboard' ? logWithNotification.addedDeputyFromPrompt() : logWithNotification.addedDeputy()
  }

  if (showDeputyAddedConfirmation)
    return <DeputyAddedConfirmation firstName={deputy['first-name']} exit={props.exit} context={props.context} />

  return (
    <div className='add-deputy-modal'>
      <MediumModal
        onClose={deputy['first-name'] ? toggleWarning : props.exit}
        open>
        <AddDeputyController
          {...props}
          deputy={deputy}
          setDeputy={setDeputy}
          handleSubmitCompleted={handleSubmitCompleted}
        />
      </MediumModal>
      {warningModal()}
    </div>
  )
}

AddDeputyModal.propTypes = {
  context: PropTypes.string.isRequired,
  exit: PropTypes.func,
  newContacts: PropTypes.instanceOf(List),
  organization: PropTypes.instanceOf(Map),
  params: PropTypes.shape({contactId: PropTypes.string}),
  showModal: PropTypes.bool,
  userConfig: PropTypes.instanceOf(Map)
}

const mapStateToProps = (state, {userConfig}) => {
  const firmOwnership = findRawResourceByAttributeId({
    rawResource: state.api['firm-ownerships'],
    attribute: 'everplan-id',
    id: userConfig.get('everplan-id')
  })

  const firm = findRawResourceById({
    rawResource: state.api.firms,
    id: userConfig.get('firm-id') || firmOwnership.get('ownable-id')
  })

  const organization = findRawResourceById({
    rawResource: state.api.organizations,
    id: firm.get('organization-id')
  })

  return {
    organization
  }
}

export default connect(mapStateToProps)(AddDeputyModal)
