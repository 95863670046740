import {List} from 'immutable'
import PropTypes from 'prop-types'

import CircleIconWrapper from './CircleIconWrapper'
import warningIcon from '../../../../../assets/warning-icon.svg'

import {addPossessive} from '../../../../../../lib/tools'
import {timeWithZoneMonthDayYear} from '../../../../../../lib/dateTools'

import './pendingUnlock.scss'

const PendingUnlock = ({
  deathReports,
  deferredReadItemCount,
  fullName,
  isDeceasedEverplanner,
  isHousehold,
  ownEverplan,
  ownerships
}) => {
  const shouldDisplayComponent = () => {
    if (ownEverplan || isDeceasedEverplanner || deferredReadItemCount === 0) return false
    if (isHousehold && deathReports.size === 2) return true
    if (!isHousehold && deathReports.size === 1) return true

    return false
  }

  if (!shouldDisplayComponent()) return null

  const deathReport = deathReports.last()
  const firstName = ownerships.find(
    ownership => ownership.get('ownable-id') === deathReport.get('death-reportable-id')
  ).get('first-name')

  return (
    <div className='header-everplan-information pending-unlock flex-container'>
      <div className='death-report-warning flex-container'>
        <CircleIconWrapper className='warning-icon'>
          <img alt='Warning Icon' src={warningIcon} />
        </CircleIconWrapper>
        <h3 className='heading flex-child'>Your request to unlock this Everplan is being processed.</h3>
      </div>
      <span className='description'>
        You reported {firstName} as deceased on {timeWithZoneMonthDayYear({date: deathReport.get('created-at')})}. Per {addPossessive(fullName)} request,
        we're holding the release of their Everplan until {timeWithZoneMonthDayYear({date: deathReport.get('created-at'), additionalHours: deathReport.get('death-verification-period') / 60})}.
      </span>
    </div>
  )
}

PendingUnlock.propTypes = {
  deferredReadItemCount: PropTypes.number,
  firstName: PropTypes.string,
  fullName: PropTypes.string,
  deathReports: PropTypes.instanceOf(List),
  isDeceasedEverplanner: PropTypes.bool,
  isHousehold: PropTypes.bool,
  ownEverplan: PropTypes.bool,
  ownerships: PropTypes.instanceOf(List)
}

export default PendingUnlock
