import {List, Map} from 'immutable'
import PropTypes from 'prop-types'

import EmptyEverplanBox from '../EmptyEverplanBox'
import ModuleBox from '../../../../../../shared_components/component_templates/ModuleBox'
import NonEmptyEverplanBox from '../NonEmptyEverplanBox'
import PendingUnlock from './PendingUnlock'

import {addPossessive} from '../../../../../../lib/tools'
import {humanizedFullNames, humanizedFirstNamesWithConjunction} from '../../../../../../lib/nameTools'
import {linkingVerb} from '../../../../../../lib/pronouns'

import './previewHeader.scss'

const PreviewHeader = props => {
  const deferredReadItemCount = props.ownEverplan ? 0 : props.deputyOwnership.getIn(['permissions', 'deferred']).size
  const fullNames = humanizedFullNames(props.ownerships)
  const isDeceasedEverplanner = props.ownerships.every(ownership => ownership.get('deceased'))
  const firstNameOnly = humanizedFirstNamesWithConjunction(props.ownerships, 'and')
  const itemCount = deferredReadItemCount + props.readItemCount

  return (
    <ModuleBox className='preview-header'>
      <div className='header-owner-information'>
        {addPossessive(fullNames)} Everplan
        {
          isDeceasedEverplanner &&
            <div className='death-report-status'>
              {firstNameOnly} {`${linkingVerb(props.ownerships.size)}`} reported as deceased
            </div>
        }
      </div>
      <PendingUnlock
        {...props}
        deferredReadItemCount={deferredReadItemCount}
        fullName={fullNames}
        isDeceasedEverplanner={isDeceasedEverplanner}
      />
      {
        itemCount ?
          <NonEmptyEverplanBox {...props} deferredReadItemCount={deferredReadItemCount} itemCount={itemCount} /> :
          <EmptyEverplanBox {...props} />
      }
    </ModuleBox>
  )
}

PreviewHeader.propTypes = {
  deputyOwnership: PropTypes.instanceOf(Map),
  deathReports: PropTypes.instanceOf(List),
  ownerships: PropTypes.instanceOf(List),
  ownEverplan: PropTypes.bool,
  readItemCount: PropTypes.number,
  deferredReadItemCount: PropTypes.number
}
export default PreviewHeader
