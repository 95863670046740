import PropTypes from 'prop-types'
import {Component} from 'react'

import {Cancel, Continue} from '../../../../shared_components/ButtonTypes'
import Forms from '../../../../shared_components/forms'
import ModalForm from '../../../../shared_components/ModalForm'

import AccountSettingsActions from '../../actions/AccountSettingsActions'
import bindUpdate from '../../../../shared_components/BoundUpdate'
import Container from '../../../../lib/Container'
import modalWrapper from '../../../../shared_components/ModalWrapper'
import storePrototype from '../../../../shared_components/StorePrototype'

import {trimString} from '../../../../lib/textTools'

import './updateAccountInformation.scss'

Container.registerStore('updatedProfile', storePrototype(AccountSettingsActions.Types.DID_UPDATE_PROFILE))

export class UpdateAccountInformation extends Component {
  constructor() {
    super()

    this.updateAccountInformation = this.updateAccountInformation.bind(this)
  }

  updateAccountInformation() {
    if (this.refs.form.validate().length === 0) {
      this.props.updating(() => {
        AccountSettingsActions.fetchAccountInformation(this.props.params.userId)
        this.context.modalClosed()
      })

      AccountSettingsActions.updateAccountInformation(this.refs.form.formData(), this.props.params.userId)
    }
  }

  render() {
    return (
      <section className='update-account-information'>
        <a className='edit-profile' onClick={this.context.showModal}>Edit</a>
        <ModalForm closer={this.context.closeModal} ref='form' title='Edit My Profile' visible={this.props.showModal}>
          <Forms.Name label='Name' name='name' defaultValue={{first_name: trimString(this.props.first_name), last_name: trimString(this.props.last_name)}} />
          {
            this.props.show_profile &&
            (
              <Forms.DateInput
                defaultValue={{month: this.props.birth_month, day: this.props.birth_day, year: this.props.birth_year}}
                label='Date of birth'
                name='dob'
                validator={Forms.Validators.birthdateValidator}
              />
            )
          }
          {
            this.props.show_profile &&
            <Forms.ZipOrCountry name='zipOrCountry' defaultValue={{zip: trimString(this.props.zip), country_code: this.props.country_code}} />
          }
          <div className='button-group'>
            <Continue loading={this.props.processing} onClick={this.updateAccountInformation}>Save</Continue>
            <Cancel onClick={this.context.closeModal} />
          </div>
        </ModalForm>
      </section>
    )
  }
}

export default modalWrapper(
  bindUpdate(
    UpdateAccountInformation,
    'updatedProfile',
    null
  )
)

UpdateAccountInformation.defaultProps = {
  params: {}
}

UpdateAccountInformation.contextTypes = {
  showModal: PropTypes.func,
  closeModal: PropTypes.func,
  modalClosed: PropTypes.func
}

UpdateAccountInformation.propTypes = {
  birth_day: PropTypes.string,
  birth_month: PropTypes.string,
  birth_year: PropTypes.string,
  country_code: PropTypes.string,
  finished: PropTypes.func,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  params: PropTypes.shape({
    userId: PropTypes.string
  }),
  processing: PropTypes.bool,
  show_profile: PropTypes.bool,
  showModal: PropTypes.bool,
  updating: PropTypes.func,
  zip: PropTypes.string
}
