import {useEffect, useState} from 'react'

import {connect} from 'react-redux'
import {createResource, readEndpoint} from 'redux-json-api'
import {List, Map} from 'immutable'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router'

import {useDeputizerData} from '../../../../../lib/hooks'
import {personalPronoun} from '../../../../../lib/pronouns'
import {cleanResponse} from '../../corp_max/redux/apiHelper'
import {currentUserConfig} from '../../../../../lib/userTools'
import {humanizedFullName, humanizedFirstNamesWithConjunction} from '../../../../../lib/nameTools'
import {isCorrectExpressRoute, enforceCorrectExpressRoute} from '../../../../../lib/urlTools'
import {ownersNotReportedDeceased} from '../../../../../lib/corpMaxDeputyTools'
import {redirectToDeputyPreview} from '../../../../../lib/urlTools'
import {
  everplanOwners,
  findResourceById,
  isOwnEverplan
} from '../../../../../lib/plan_data/userData'

import NewLoader from '../../../../../shared_components/NewLoader'
import Closer from '../../../../../shared_components/core/closer/Closer'
import Modals from '../../../../../shared_components/core/modals/Modals'
import routerUtils from '../../../../../lib/routerUtils'
import Logger from '../../../../../lib/NewLogger'

import './corpMaxDeathReportModal.scss'

const CorpMaxDeathReportModal = props => {
  const everplanId = parseInt(props.params.everplanId, 10)

  const {deputizers} = useDeputizerData(props.userConfig)

  const [deputyOwnership, setDeputyOwnership] = useState()
  const [ownerToMarkDeceased, setOwnerToMarkDeceased] = useState(Map())
  const [displayName, setDisplayName] = useState(humanizedFullName(ownerToMarkDeceased))
  const [ownerships, setOwnerships] = useState()
  const [processing, setProcessing] = useState(false)

  const getDeputyEverplanOwners = () => {
    props.readEndpoint(`users/${props.userConfig.get('id')}?include=deputy-ownerships.secondary-everplan-owner`)
  }

  useEffect(() => {
    props.readEndpoint(`everplans/${props.params.everplanId}`)
    getDeputyEverplanOwners()

    return () => enforceCorrectExpressRoute()
  }, [])

  useEffect(() => {
    if (!deputizers?.isEmpty() && !props.ownEverplan) {
      const foundDeputyOwnership = deputizers.find(
        deputizer => deputizer.get('everplan-id') === everplanId
      )

      const owners = everplanOwners({
        ownership: foundDeputyOwnership,
        ownEverplan: props.ownEverplan,
        owners: props.owners
      })

      setDeputyOwnership(foundDeputyOwnership)
      setOwnerships(owners)
      setOwnerToMarkDeceased(ownersNotReportedDeceased({
        deathReports: props.deathReports, ownerships: owners
      }).first())
    }
  }, [])

  useEffect(() => {
    setDisplayName(humanizedFullName(ownerToMarkDeceased))
  }, [ownerToMarkDeceased])

  const livingOwnerDetails = () => {
    if (ownerToMarkDeceased.isEmpty())
      return ownerships.find(ownership => !ownership.get('deceased'))
    else
      return ownerToMarkDeceased
  }

  const pronoun = () => {
    if (!ownerships) return null

    const userPronoun = ownerships.size === 1 ?
      livingOwnerDetails().get('pronoun') :
      null

    return personalPronoun(userPronoun, 'modifying-possessive')
  }

  const firstNameOnly = ownerships ?
    humanizedFirstNamesWithConjunction(ownerships, 'and') :
    null

  const logEvent = () => {
    Logger.log({
      name: 'submitted_user_as_deceased',
      payload: {
        actor: 'deputy',
        context: 'everplan_preview',
        everplan_id: props.params.everplanId
      }
    })
  }

  const goToStepTwo = () => { routerUtils.push(`cm/report-death/${props.params.everplanId}/2`) }

  const buildResourceAndCreateDeathReport = ownership => {
    const resource = {
      type: 'death-reports',
      attributes: {
        'death-reportable-type': ownership.get('ownable-type'),
        'death-reportable-id': ownership.get('ownable-id')
      }
    }

    props.createResource(resource).then(() => {
      setProcessing(false)
      props.readEndpoint('death-reports')
      goToStepTwo()
    })
  }

  const owner = id => {
    const splitId = id.split('|')

    return ownerships.find(
      ownership => (ownership.get('ownable-type') === splitId[0] && ownership.get('ownable-id') === parseInt(splitId[1], 10))
    )
  }

  const markAsDeceased = id => {
    setProcessing(true)
    logEvent()

    if (id) {
      const ownership = owner(id)

      setOwnerToMarkDeceased(ownership)

      buildResourceAndCreateDeathReport(ownership)
    } else {
      const livingOwner = livingOwnerDetails()

      buildResourceAndCreateDeathReport(livingOwner)
    }
  }

  const closeModal = () => redirectToDeputyPreview(props.userEverplan)

  const reportModalCloser = () => <Closer closer={closeModal} />

  const propsForChildren = {
    ...props,
    closeModal,
    deputyOwnership,
    displayName,
    firstNameOnly,
    goToStepTwo,
    markAsDeceased,
    ownerships,
    ownerToMarkDeceased,
    processing,
    pronoun: pronoun()
  }

  if (!isCorrectExpressRoute ||
    !ownerships ||
    props.userEverplan.isEmpty()
  ) {
    return <NewLoader loading={true} />
  } else {
    return (
      <div className='death-report-modal'>
        <Modals.WrappedPopUpModal showModalOnMount={true} closerComponent={reportModalCloser}>
          {routerUtils.childrenWithProps(props.children, propsForChildren)}
        </Modals.WrappedPopUpModal>
      </div>
    )
  }
}

CorpMaxDeathReportModal.propTypes = {
  closeModal: PropTypes.func,
  deathReports: PropTypes.instanceOf(List),
  deputyOwnership: PropTypes.instanceOf(Map),
  readEndpoint: PropTypes.func,
  createResource: PropTypes.func,
  params: PropTypes.shape({
    everplanId: PropTypes.string
  }),
  ownEverplan: PropTypes.bool,
  owners: PropTypes.instanceOf(List),
  ownerships: PropTypes.instanceOf(List),
  userConfig: PropTypes.instanceOf(Map),
  userEverplan: PropTypes.instanceOf(Map)
}

export const mapStateToProps = (state, {params}) => {
  const userConfig = currentUserConfig(state.api)
  const deathReports = state.api['death-reports'] ? cleanResponse(state.api['death-reports']) : List()
  const ownEverplan = isOwnEverplan({everplanPreviewId: params.everplanId, owners: state.owners})
  const owners = state.owners ? cleanResponse(state.owners) : List()
  const userEverplan = findResourceById({resourceList: cleanResponse(state.api.everplans), id: params.everplanId})

  return ({
    deathReports,
    userEverplan,
    ownEverplan,
    owners,
    userConfig
  })
}

export default connect(mapStateToProps, {createResource, readEndpoint})(withRouter(CorpMaxDeathReportModal))
